export default {
  name: "MMortgageMetalPrice",
  data() {
    return {
      dataForm: {
        add: {
          setupDate: "",
          metalTypeId: "",
          marketPrice: "",
          description: "",
          status: "",
        },
        edit: {
          setupDate: "",
          metalTypeId: "",
          marketPrice: "",
          description: "",
          status: "",
          mortgageMetalPriceId: "",
        },
        detail: {
          setupDate: "",
          marketPrice: "",
          description: "",
          metalTypeId: "",
          status: "",
          mortgageMetalPriceId: "",
          createdByUserCode: "",
          createdByUserName: "",
          createdDate: "",
          updatedDate: "",
          updatedByUserCode: "",
          updatedByUserName: "",
          authorizedDate: "",
          authorizedByUserCode: "",
          authorizedByUserName: "",
        },
      },
      options: {
        metalType: [],
      },
      property: {
        filterDto: {
          setupDate: "",
        },
        animation: {
          addMortgageMetalPrice: {
            isLoading: false,
          },
          editMortgageMetalPrice: {
            isLoading: false,
          },
        },
        modal: {
          showModalAddMortgageMetalPrice: false,
          showModalEditMortgageMetalPrice: false,
          showModalDetailMortgageMetalPrice: false,
          label: "",
        },
        listElement: {
          MortgageMetalPrice: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
      },
      table: {
        data: {
          MortgageMetalPrice: [],
        },
      },
    };
  },
  methods: {
    async getMortgageMetalPrice() {
      this.table.data.MortgageMetalPrice = [];
      this.property.listElement.MortgageMetalPrice.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-metal-price",
          payload: {
            page: 0,
            size: this.property.listElement.MortgageMetalPrice.perPage,
            setupDate: "",
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.MortgageMetalPrice = resp.data.data.content;
            this.property.listElement.MortgageMetalPrice.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataMortgageMetalPrice(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorListMortgageMetalPrice(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.MortgageMetalPrice.downloading = false;
        }, 1000);
      }
    },

    async authorizationMortgageMetalPrice(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "loan",
                reqUrl:
                  "mortgage-metal-price/authorized/" +
                  props.row.mortgageMetalPriceId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.setupDate = "";
                  this.property.listElement.MortgageMetalPrice.rows = 0;
                  this.property.listElement.MortgageMetalPrice.currentPage = 1;
                  this.getMortgageMetalPrice();
                }, 1000);
              } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                this.$buefy.dialog.alert({
                  title: "Harap Tunggu !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },

    async addMortgageMetalPrice() {
      const payload = {
        setupDate: this.dataForm.add.setupDate,
        metalTypeId: this.dataForm.add.metalTypeId,
        marketPrice: this.dataForm.add.marketPrice,
        description: this.dataForm.add.description,
        status: this.dataForm.add.status,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addMortgageMetalPrice.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl: "mortgage-metal-price",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.dialog.alert({
                    title: "Success !",
                    message: "Berhasil menyimpan data",
                    confirmText: "Ok",
                    type: "is-success",
                    onConfirm: () => {
                      this.property.filterDto.setupDate = "";
                      this.property.listElement.MortgageMetalPrice.rows = 0;
                      this.property.listElement.MortgageMetalPrice.currentPage = 1;
                      this.getMortgageMetalPrice();
                    },
                  });
                  this.closeModalAddMortgageMetalPrice();
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              console.log(error.response);
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.addMortgageMetalPrice.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async getReferenceMetalType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "mortgage-metal-type",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.metalType = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.map((data) => {
            const text = `${data.metalTypeName}`;
            const value = data.metalTypeId;
            this.options.metalType.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async editMortgageMetalPrice() {
      const payload = {
        mortgageMetalPriceId: this.dataForm.edit.mortgageMetalPriceId,
        setupDate: this.dataForm.edit.setupDate,
        marketPrice: this.dataForm.edit.marketPrice,
        metalTypeId: this.dataForm.edit.metalTypeId,
        description: this.dataForm.edit.description,
        status: this.dataForm.edit.status,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.editMortgageMetalPrice.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl:
                  "mortgage-metal-price/" +
                  this.dataForm.edit.mortgageMetalPriceId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.dialog.alert({
                    title: "Success !",
                    message: "Berhasil mengubah data",
                    confirmText: "Ok",
                    type: "is-success",
                    onConfirm: () => {
                      this.property.filterDto.setupDate = "";
                      this.property.listElement.MortgageMetalPrice.currentPage = 1;
                      this.property.listElement.MortgageMetalPrice.rows = 0;
                      this.getMortgageMetalPrice();
                    },
                  });
                  this.closeModalEditMortgageMetalPrice();
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.editMortgageMetalPrice.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },

    showModalAddMortgageMetalPrice() {
      this.property.modal.showModalAddMortgageMetalPrice = true;
      this.property.modal.label = "FORM ADD HARGA PASARAN EMAS";
    },

    async showModalEditMortgageMetalPrice(props) {
      this.property.modal.showModalEditMortgageMetalPrice = true;
      this.property.modal.label = "FORM EDIT HARGA PASARAN EMAS";
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "mortgage-metal-price/" + props.row.mortgageMetalPriceId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.edit.setupDate = resp.data.data.setupDate;
            this.dataForm.edit.marketPrice = resp.data.data.marketPrice;
            this.dataForm.edit.description = resp.data.data.description;
            this.dataForm.edit.mortgageMetalPriceId =
              resp.data.data.mortgageMetalPriceId;
            this.dataForm.edit.metalTypeId =
              resp.data.data.rmortgageMetalType?.metalTypeId;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },

    async showModalDetailMortgageMetalPrice(props) {
      this.property.modal.showModalDetailMortgageMetalPrice = true;
      this.property.modal.label = "FORM DETAIL HARGA PASARAN EMAS";
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl: "mortgage-metal-price/" + props.row.mortgageMetalPriceId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.detail.setupDate = resp.data.data.setupDate;
            this.dataForm.detail.marketPrice = resp.data.data.marketPrice;
            this.dataForm.detail.description = resp.data.data.description;
            this.dataForm.detail.status = resp.data.data.status.statusName;
            this.dataForm.detail.metalTypeId =
              resp.data.data.rmortgageMetalType?.metalTypeId;
            this.dataForm.detail.mortgageMetalPriceId =
              resp.data.data.mortgageMetalPriceId;
            this.dataForm.detail.createdByUserCode =
              resp.data.data.createdByUserCode;
            this.dataForm.detail.createdByUserName =
              resp.data.data.createdByUserName;
            this.dataForm.detail.createdDate = resp.data.data.createdDate;
            this.dataForm.detail.updatedDate = resp.data.data.updatedDate;
            this.dataForm.detail.updatedByUserCode =
              resp.data.data.updatedByUserCode;
            this.dataForm.detail.updatedByUserName =
              resp.data.data.updatedByUserName;
            this.dataForm.detail.authorizedDate = resp.data.data.authorizedDate;
            this.dataForm.detail.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.detail.authorizedByUserName =
              resp.data.data.authorizedByUserName;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },

    closeModalDetailMortgageMetalPrice() {
      this.property.modal.showModalDetailMortgageMetalPrice = false;
      this.dataForm.detail.mortgageMetalPriceId = "";
      this.dataForm.detail.setupDate = "";
      this.dataForm.detail.marketPrice = "";
      this.dataForm.detail.metalTypeId = "";
      this.dataForm.detail.description = "";
      this.dataForm.detail.status = false;
    },

    closeModalEditMortgageMetalPrice() {
      this.property.modal.showModalEditMortgageMetalPrice = false;
      this.dataForm.edit.mortgageMetalPriceId = "";
      this.dataForm.edit.setupDate = "";
      this.dataForm.edit.marketPrice = "";
      this.dataForm.edit.metalTypeId = "";
      this.dataForm.edit.description = "";
      this.dataForm.edit.status = false;
    },
    closeModalAddMortgageMetalPrice() {
      this.property.animation.addMortgageMetalPrice.isLoading = false;
      this.property.modal.showModalAddMortgageMetalPrice = false;
      this.dataForm.add.setupDate = "";
      this.dataForm.add.metalTypeId = "";
      this.dataForm.add.marketPrice = "";
      this.dataForm.add.description = "";
      this.dataForm.add.status = false;
    },

    async deleteMortgageMetalPrice(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "loan",
                reqUrl:
                  "mortgage-metal-price/" + props.row.mortgageMetalPriceId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.filterDto.setupDate = "";
                  this.property.listElement.MortgageMetalPrice.currentPage = 1;
                  this.property.listElement.MortgageMetalPrice.rows = 0;
                  this.getMortgageMetalPrice();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async changePaginationMortgageMetalPrice(event) {
      this.table.data.MortgageMetalPrice = [];
      this.property.listElement.MortgageMetalPrice.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-metal-price",
          payload: {
            page: event - 1,
            size: this.property.listElement.MortgageMetalPrice.perPage,
            setupDate: this.property.filterDto.setupDate,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.MortgageMetalPrice = resp.data.data.content;
            this.property.listElement.MortgageMetalPrice.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataMortgageMetalPrice(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorListMortgageMetalPrice(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.MortgageMetalPrice.downloading = false;
        }, 1000);
      }
    },
    async searchBySetupDateMortgageMetalPrice(event) {
      this.table.data.MortgageMetalPrice = [];
      this.property.listElement.MortgageMetalPrice.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-metal-price",
          payload: {
            page: 0,
            size: this.property.listElement.MortgageMetalPrice.perPage,
            setupDate: event,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.MortgageMetalPrice = resp.data.data.content;
            this.property.listElement.MortgageMetalPrice.rows =
              resp.data.data.totalElements;
            this.property.listElement.MortgageMetalPrice.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataMortgageMetalPrice(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorListMortgageMetalPrice(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.MortgageMetalPrice.downloading = false;
        }, timeout + 500);
      }
    },
    handleErrorListMortgageMetalPrice(error) {
      console.log(error.response);
      this.table.data.MortgageMetalPrice = [];
      this.property.listElement.MortgageMetalPrice.rows = 0;
      this.property.listElement.MortgageMetalPrice.currentPage = 1;
      this.property.listElement.MortgageMetalPrice.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataMortgageMetalPrice(resp) {
      this.table.data.MortgageMetalPrice = [];
      this.property.listElement.MortgageMetalPrice.rows = 0;
      this.property.listElement.MortgageMetalPrice.message = resp.data.message;
    },
  },
  mounted() {
    this.getMortgageMetalPrice();
    this.getReferenceMetalType();
  },
};
